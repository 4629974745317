var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.students
        ? _c(
            "program-alert",
            [
              _vm._v(" Recently completed reports "),
              _c(
                "v-chip",
                { attrs: { small: "", color: "secondary darken-2" } },
                [_vm._v(_vm._s(_vm.students[0].name))]
              ),
              _vm._v(" and "),
              _c(
                "v-chip",
                { attrs: { small: "", color: "secondary darken-2" } },
                [_vm._v(_vm._s(_vm.students[1].name))]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          model: {
            value: _vm.editDialog,
            callback: function($$v) {
              _vm.editDialog = $$v
            },
            expression: "editDialog"
          }
        },
        [
          _vm.editDialog
            ? _c("program-icon-select", {
                attrs: { icon_filepath: _vm.programData.program.icon_filepath },
                on: {
                  close: function($event) {
                    _vm.editDialog = false
                  },
                  select: _vm.selectIconFile
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-space-beetween" },
                [
                  _c(
                    "card-tile",
                    { attrs: { title: "Program" } },
                    [
                      _c("h3", [_vm._v(_vm._s(_vm.programData.program.name))]),
                      _c("v-avatar", { attrs: { size: "150" } }, [
                        _c("img", {
                          attrs: { src: _vm.programData.program.icon_filepath }
                        })
                      ]),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", color: "secondary" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            color: "primary",
                                            icon: "",
                                            small: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.editDialog = true
                                            }
                                          }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v("mdi-pencil")])],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [_c("span", [_vm._v("Change Program Icon")])]
                      )
                    ],
                    1
                  ),
                  _c(
                    "card-tile",
                    { staticClass: "mx-6", attrs: { title: "Instructor" } },
                    [
                      _c("h3", [_vm._v(_vm._s(_vm.instructor.name))]),
                      _c(
                        "v-avatar",
                        { staticClass: "my-3", attrs: { size: "100" } },
                        [
                          _c("img", {
                            attrs: { src: _vm.instructor.profile_photo_url }
                          })
                        ]
                      ),
                      _c(
                        "h5",
                        {
                          staticClass: "font-weight-light",
                          staticStyle: { "margin-bottom": "4px" }
                        },
                        [_vm._v(" " + _vm._s(_vm.instructor.email) + " ")]
                      )
                    ],
                    1
                  ),
                  _c("session-select", {
                    attrs: {
                      sessions: _vm.instructor.sessions,
                      session: _vm.sessionData
                    },
                    on: {
                      "update:session": function($event) {
                        _vm.sessionData = $event
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "card-tile",
                {
                  staticClass: "mt-3",
                  attrs: { "full-width": "", title: "Credit Scores" }
                },
                [
                  _c("apex-bar-chart", {
                    key: _vm.sessionData.id,
                    attrs: {
                      height: 340,
                      labelColor: "#fff",
                      borderColor: "#333",
                      average: parseInt(
                        _vm.programData.charts.credit_score.average.replace(
                          ",",
                          ""
                        )
                      ),
                      data: _vm.charts.credit_score.series.data,
                      categories: _vm.charts.credit_score.categories
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("student-list", {
                key: _vm.sessionData.id,
                attrs: { students: _vm.students },
                on: { "add-students": _vm.addStudents }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "card-tile",
                {
                  staticClass: "mt-3",
                  attrs: { "full-width": "", title: "Credit Balance Due" }
                },
                [
                  _c("apex-column-chart", {
                    key: _vm.sessionData.id,
                    attrs: {
                      title: "Credit Balance Due",
                      height: 340,
                      colorPalette: "primary darken",
                      average: parseInt(
                        _vm.programData.charts.credit_balance_due.average.replace(
                          ",",
                          ""
                        )
                      ),
                      data: _vm.charts.credit_balance_due.series.data,
                      categories: _vm.charts.credit_balance_due.categories
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "card-tile",
                {
                  staticClass: "mt-3",
                  attrs: { "full-width": "", title: "Property Debt" }
                },
                [
                  _c("apex-column-chart", {
                    key: _vm.sessionData.id,
                    attrs: {
                      height: 340,
                      colorPalette: "alternate1",
                      average: parseInt(
                        _vm.programData.charts.property_debt.average.replace(
                          ",",
                          ""
                        )
                      ),
                      data: _vm.charts.property_debt.series.data,
                      categories: _vm.charts.property_debt.categories
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "card-tile",
                {
                  staticClass: "mt-3",
                  attrs: { "full-width": "", title: "Utility Debt" }
                },
                [
                  _c("apex-column-chart", {
                    key: _vm.sessionData.id,
                    attrs: {
                      height: 340,
                      colorPalette: "alternate4",
                      average: parseInt(
                        _vm.programData.charts.utility_debt.average.replace(
                          ",",
                          ""
                        )
                      ),
                      data: _vm.charts.utility_debt.series.data,
                      categories: _vm.charts.utility_debt.categories
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }