<template>
    <div>
        <program-alert v-if="students">
            Recently completed reports
            <v-chip small color="secondary darken-2">{{
                students[0].name
            }}</v-chip>
            and
            <v-chip small color="secondary darken-2">{{
                students[1].name
            }}</v-chip>
        </program-alert>
        <v-dialog v-model="editDialog" max-width="800">
            <program-icon-select
                v-if="editDialog"
                :icon_filepath="programData.program.icon_filepath"
                @close="editDialog = false"
                @select="selectIconFile"
            >
            </program-icon-select>
        </v-dialog>
        <v-row>
            <v-col cols="6">
                <div class="d-flex justify-space-beetween">
                    <card-tile title="Program">
                        <h3>{{ programData.program.name }}</h3>
                        <v-avatar size="150">
                            <img :src="programData.program.icon_filepath" />
                        </v-avatar>
                        <v-tooltip top color="secondary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    @click="editDialog = true"
                                    ><v-icon>mdi-pencil</v-icon></v-btn
                                >
                            </template>
                            <span>Change Program Icon</span>
                        </v-tooltip>
                    </card-tile>
                    <card-tile title="Instructor" class="mx-6">
                        <h3>{{ instructor.name }}</h3>
                        <v-avatar size="100" class="my-3">
                            <img :src="instructor.profile_photo_url" />
                        </v-avatar>
                        <h5
                            class="font-weight-light"
                            style="margin-bottom: 4px"
                        >
                            {{ instructor.email }}
                        </h5>
                    </card-tile>
                    <session-select
                        :sessions="instructor.sessions"
                        :session.sync="sessionData"
                    ></session-select>
                </div>
                <card-tile full-width title="Credit Scores" class="mt-3">
                    <apex-bar-chart
                        :key="sessionData.id"
                        :height="340"
                        labelColor="#fff"
                        borderColor="#333"
                        :average="
                            parseInt(
                                programData.charts.credit_score.average.replace(
                                    ',',
                                    ''
                                )
                            )
                        "
                        :data="charts.credit_score.series.data"
                        :categories="charts.credit_score.categories"
                    ></apex-bar-chart>
                </card-tile>
            </v-col>
            <v-col cols="6">
                <student-list
                    :students="students"
                    :key="sessionData.id"
                    @add-students="addStudents"
                ></student-list>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <card-tile full-width title="Credit Balance Due" class="mt-3">
                    <apex-column-chart
                        :key="sessionData.id"
                        title="Credit Balance Due"
                        :height="340"
                        colorPalette="primary darken"
                        :average="
                            parseInt(
                                programData.charts.credit_balance_due.average.replace(
                                    ',',
                                    ''
                                )
                            )
                        "
                        :data="charts.credit_balance_due.series.data"
                        :categories="charts.credit_balance_due.categories"
                    ></apex-column-chart>
                </card-tile>
            </v-col>
            <v-col cols="4">
                <card-tile full-width title="Property Debt" class="mt-3">
                    <apex-column-chart
                        :key="sessionData.id"
                        :height="340"
                        colorPalette="alternate1"
                        :average="
                            parseInt(
                                programData.charts.property_debt.average.replace(
                                    ',',
                                    ''
                                )
                            )
                        "
                        :data="charts.property_debt.series.data"
                        :categories="charts.property_debt.categories"
                    ></apex-column-chart>
                </card-tile>
            </v-col>
            <v-col cols="4">
                <card-tile full-width title="Utility Debt" class="mt-3">
                    <apex-column-chart
                        :key="sessionData.id"
                        :height="340"
                        colorPalette="alternate4"
                        :average="
                            parseInt(
                                programData.charts.utility_debt.average.replace(
                                    ',',
                                    ''
                                )
                            )
                        "
                        :data="charts.utility_debt.series.data"
                        :categories="charts.utility_debt.categories"
                    ></apex-column-chart>
                </card-tile>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    name: "program-instructor",
    components: {
        ProgramAlert: () => import("../components/ProgramAlert"),
        CardTile: () => import("../components/Dashboard/CardTile"),
        ApexBarChart: () => import("@/components/lib/Charts/ApexBarChart"),
        ApexColumnChart: () =>
            import("@/components/lib/Charts/ApexColumnChart"),
        StudentList: () => import("./StudentList.vue"),
        SessionSelect: () => import("./SessionSelect"),
        ProgramIconSelect: () => import("./ProgramIconSelect"),
    },
    props: {
        program: {
            type: Object,
            required: true,
        },
        instructor: {
            type: Object,
            required: true,
        },
        session: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            sessionData: this.session,
            programData: this.program,
            editDialog: false,
            addedStudents: [],
        };
    },
    computed: {
        students() {
            return this.sessionIndex === -1
                ? []
                : this.addedStudents.concat(
                      this.instructor.sessions[this.sessionIndex].students
                  );
        },
        charts() {
            return this.sessionIndex === -1
                ? []
                : Object.assign(
                      {},
                      this.instructor.sessions[this.sessionIndex].charts
                  );
        },
        sessionIndex() {
            return this.instructor.sessions.findIndex(
                (session) => this.sessionData.id === session.id
            );
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        selectIconFile(icon_filepath) {
            this.programData.program.icon_filepath = icon_filepath;
            this.editDialog = false;
        },
        addStudents(students) {
            this.addedStudents = students.concat(this.addedStudents);
        },
    },
};
</script>

<style scoped>
</style>